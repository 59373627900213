<template>
  <v-container class="ma-0 pa-0" fluid>
    <!-- Image and title -->
    <v-row class="ma-0">
      <v-img
        lazy-src="../../public/backgrounds/home_background-lazy.jpg"
        src="../../public/backgrounds/home_background.jpg"
        transition="fade-transition"
        :height="$vuetify.breakpoint.xsOnly ? 450 : 550"
        alt="Pozadie-domov"
      >
        <v-overlay
          class="pt-16 unselectable"
          :absolute="true"
          :value="true"
          :opacity="0.8"
        >
          <v-container
            :class="'pa-0 px-4 ' + (mobileScreen ? 'text-center' : 'mt-16')"
          >
            <p class="site-text__h3 white--text">Vitajte</p>
            <p class="mt-2 mb-6" style="max-width: 900px">
              V spoločnosti BoltLine, s. r. o. sme silne prozákaznícky
              orientovaní a všeobecne známi vysokou úrovňou profesionality,
              komunikácie a dôslednosti. Garantujeme hladký priebeh, plnenie
              termínov a celkový komfort počas celej spolupráce, o čom svedčia
              pozitívne spätné väzby od našich zákazníkov.
            </p>
            <v-btn to="/kontakt" color="secondary" outlined> Kontakt </v-btn>
          </v-container>
        </v-overlay>
      </v-img>
    </v-row>
    <!-- CONTENT -->
    <v-row class="ma-0 my-8" justify="center">
      <!-- WHY US... -->
      <v-row
        class="my-8 ma-0"
        :style="'max-width: ' + (mobileScreen ? '100vw' : '70vw')"
        align="center"
      >
        <v-col cols="12" lg="5" class="site-text">
          <p class="site-text__h4">
            Prečo si vybrať
            <span class="primary--text"> Nás... </span>
          </p>
          <div
            v-for="([title, text, icon], i) in reasonsWhyUs"
            :key="i"
            class="d-flex align-start py-4"
          >
            <v-icon class="mt-2" size="30">
              {{ icon }}
            </v-icon>
            <div>
              <v-card-title class="py-0">{{ title }}</v-card-title>
              <v-card-text class="py-0"> {{ text }} </v-card-text>
            </div>
          </div>
        </v-col>
        <v-col cols="12" lg="7">
          <v-img
            src="../../public/images/home/house.svg"
            lazy-src="../../public/images/home/house.png"
            alt="house-electricity"
          >
            <template v-slot:placeholder>
              <v-row class="fill-height ma-0" align="center" justify="center">
                <v-progress-circular
                  indeterminate
                  color="secondary"
                ></v-progress-circular>
              </v-row>
            </template>
          </v-img>
        </v-col>
      </v-row>
      <!-- SERVICES -->
      <v-row
        class="my-16 ma-0"
        :style="'max-width: ' + (mobileScreen ? '100vw' : '70vw')"
        justify="center"
      >
        <p class="site-text__h4">Naše <span class="bold">služby:</span></p>
        <v-row class="ma-0" justify="center">
          <v-col
            v-for="(section, i) in serviceSections"
            class="d-flex justify-center"
            :key="i"
            cols="6"
            lg="4"
          >
            <HomePageServiceTile :section="section" />
          </v-col>
        </v-row>
      </v-row>
      <!-- PROJECTS -->
      <v-row
        class="my-16 ma-0"
        justify="center"
        :style="'max-width: ' + (mobileScreen ? '100vw' : '70vw')"
      >
        <!-- title -->
        <p class="site-text__h4">Nedávne <span class="bold">projekty:</span></p>
        <v-row class="ma-0" justify="center">
          <v-col
            v-for="(project, index) in latestProjects"
            class="d-flex justify-center"
            :key="index"
            cols="6"
            sm="4"
            lg="3"
          >
            <HomePageProjectTile
              class="ma-2"
              :title="project.title"
              :subtitle="project.subtitle"
              :folder="project.folder"
              :main-img-index="project.mainImgIndex"
              :services="project.services"
            />
          </v-col>
        </v-row>
        <v-row class="mx-0 mt-8" justify="center">
          <v-btn color="text" to="/projekty" large outlined>
            Všetky projekty
          </v-btn>
        </v-row>
      </v-row>
    </v-row>
  </v-container>
</template>

<script>
import HomePageServiceTile from "./HomePageServiceTile.vue";
import HomePageProjectTile from "./HomePageProjectTile.vue";
export default {
  name: "HomePage",
  components: {
    HomePageServiceTile,
    HomePageProjectTile,
  },
  data() {
    return {
      serviceSections: [
        {
          title: "Fotovoltika",
          path: "fotovoltika",
          image: "service-fv.jpg",
          subSections: [
            ["Pre domácnosť", "#FV-dom", "mdi-home-lightning-bolt-outline"],
            ["Pre firmy", "#FV-firma", "mdi-solar-panel-large"],
            ["Car-porty", "#carport", "mdi-car-electric-outline"],
            ["Batérie", "#bateria", "mdi-home-battery-outline"],
          ],
        },
        {
          title: "Bleskozvod",
          path: "bleskozvod",
          image: "service-blesk.jpg",
          subSections: [
            ["Klasický", "#bleskozvod", "mdi-lightning-bolt-outline"],
            ["Izolovaný", "#izolovany_bleskozvod", "mdi-lightning-bolt-circle"],
          ],
        },
        {
          title: "Projekčná činnosť",
          path: "projekt",
          image: "project.jpg",
          subSections: [
            ["Domácnosť", "#projekt-elektro", "mdi-floor-plan"],
            [
              "Elek. prípojka",
              "#projekt-pripojky",
              "mdi-meter-electric-outline",
            ],
            ["Fotovoltika", "#projekt-FV", "mdi-solar-power-variant-outline"],
          ],
        },
        {
          title: "Elektroinštalácie",
          path: "elektroinstalacia",
          image: "service-elek.jpg",
          subSections: [
            [
              "Domová prípojka",
              "#domova_pripojka",
              "mdi-home-lightning-bolt-outline",
            ],
            ["Rozvádzač", "#elek_rozvadzac", "mdi-dip-switch"],
            ["Elek. rozvody", "#elek_rozvody", "mdi-power-plug-outline"],
            ["Dátové rozvody", "#datove_rozvody", "mdi-lan-pending"],
            [
              "Elek. zariadenia",
              "#elek_zariadenia",
              "mdi-lightbulb-group-outline",
            ],
          ],
        },
        {
          title: "Rekuperácia",
          path: "rekuperacia",
          image: "service-rekup.jpg",
          subSections: [
            ["centrálna", "#centralna-rekuperacia", "mdi-hvac"],
            ["lokálna", "#lokalna-rekuperacia", "mdi-air-filter"],
          ],
        },
        {
          title: "Elektrické kúrenie",
          path: "kurenie",
          image: "service-infra.jpg",
          subSections: [
            ["Infra-folie", "#infra-folie", "mdi-thermometer-lines"],
            ["Odporové rohože", "#vykurovacie-rohoze", "mdi-heating-coil"],
            ["Infra-žiariče", "#infra-ziarice", "mdi-artboard"],
          ],
        },
      ],
      numOfProjectsToShow: 8,
      reasonsWhyUs: [
        [
          "Kvalitné produkty",
          "Používame kvalitné produkty a materiály pre zabezpečenie trvácnosti a spoľahlivosti",
          "mdi-puzzle-check-outline",
        ],
        [
          "Profesionálna montáž",
          "Pri práci dbáme na presnosť, dizajn ale hlavne na funkčnosť a spoľahlivosť",
          "mdi-wrench-check-outline",
        ],
        [
          "Odborné servisné prehliadky",
          "Zabezpečujeme pravidelné servisné kontroly a revízie",
          "mdi-clipboard-check-outline",
        ],
      ],
    };
  },
  computed: {
    mobileScreen() {
      return this.$vuetify.breakpoint.smAndDown;
    },
    latestProjects() {
      let allProjects = this.$store.getters["projects/getProjects"];
      let filteredProjects = [];
      for (let i = 0; i < this.numOfProjectsToShow; i++) {
        filteredProjects.push(allProjects[i]);
      }
      return filteredProjects;
    },
  },
};
</script>
